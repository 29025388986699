// RunbookSelector.tsx// RunbookSelector.tsx
import React, { useEffect } from 'react';
import { Option, Select, OptionGroup } from "@leafygreen-ui/select";
import Banner from "@leafygreen-ui/banner";
import { Label, Description } from "@leafygreen-ui/typography";
import EngagementsPrerequisites from './EngagementsPrerequisites'; // Import the new component

interface RunbookSelectorProps {
  selectedEngagement: string;
  setRunbook: (value: string) => void;
  runbook: string;
  registerValidation: (key: string, validateFunc: () => ValidationError) => void;
  unregisterValidation: (key: string) => void;
  setPostDescription: (description: string) => void;
}

interface ValidationError {
  validation: boolean;
  message: string;
}

// ------------------------------------------------------------------------
// ------ Runbook Description
// ------------------------------------------------------------------------

const runbookDescriptions: { [key: string]: string } = {
  "Metric Review": "Analyzing the performance and health of our customers’ Atlas clusters: Identify and explain any issues while providing actionable recommendations. This session should not be used as a discovery call. By conducting a deep dive into metrics using all the tools available to Solutions Architects, we can uncover most potential issues and offer tailored advice. In scenarios where no specific root cause is identified, we will share our understanding with the customer and position Professional Services (PS) for hands-on improvements.",
  "Backup Optimization": "Evaluating and recommending adjustments to backup policies: We aim to help customers manage their backup costs while gaining a clear understanding of the cost drivers. Our analysis highlights the value of each backup option and is discussed with the customer to ensure it aligns with their needs. Since potential solutions may require changes to cluster settings and architecture, these conversations must involve a technical team member.",
  "Cluster Optimization": "Provide a comprehensive analysis of the findings and recommendations from running the mSizer script on MongoDB clusters. This should include a detailed evaluation of the current cluster workload, resource utilization, and opportunities for optimization.",
  "Technical Success Plan": "Validate technical goals, sizing, architectural composition, pre-onboarding checklist, timelines and milestones for go-lives, comprehensive project planning, and disaster recovery planning.",
  "Support Go-Live Lookback": "This meeting is designed to conclude the Migration phase, following our Post-Go Live support engagement: During this session, we will present the outcomes of our interventions, address any remaining blockers, highlight the positive impact of the MongoDB Account team’s efforts.",
  "Support Go-Live": "An ideal engagement to position early in the Atlas NWL migration process: This engagement focuses on validating the customer’s technical team’s understanding of Atlas, ensuring they can successfully implement the recommended configuration and architecture. For very large dedicated WL, we go further by reviewing the customer’s migration plan, identifying opportunities for additional Atlas services, and ensuring they are fully equipped to manage their new production workloads effectively.",
  "Pre-Prod Design Validation" : "For Customers with an existing MongoDB Schema, during that 1h-session we will identify potential performance issues or bottlenecks that could emerge once they move to production receiving a higher workload.",
  "Design Review" : "For Premier Opportunity only! We guide the customer during a whiteboard session on data modeling and provide expert advice on programmatically interacting with the data effectively.",
  "Customer Diagram Techstack Review" : "This runbook helps identify new NWLs by uncovering underutilized MongoDB/Atlas capabilities. Using the customer’s high-level architecture diagram as a foundation, this session facilitates a discovery discussion with our Champion to explore their current architecture and assess their interest in initiating PreSales conversations.",
  // Add more descriptions as needed
};
const runbookPreReq: { [key: string]: string[] } = {
  "Metric Review": [
    "The cluster is at least an M30+.",
    "The customer requested this review, potentially due to observed issues or alerts.",
    "Multiple metrics on the dashboard indicate a need for a health check, and the issue has been confirmed with the customer."
  ],
  "Backup Optimization": [
    "More than 30% of the cluster costs are spent on backups compared to the compute component of ARR in Atlas.",
    "The cluster being evaluated must be an M40 or larger."
  ],
  "Cluster Optimization": [
    "The customer has provided a high-level diagram of their current tech stack architecture.",
    "The account's total consumption is $100K or more, or it is considered a Premier account.",
    "The customer is open to receiving recommendations for optimizing their environment.",
    "If the account has a PreSales SA assigned, they are aware of this initiative."
  ],
  "Technical Success Plan": [
    "The NWL Approval Checklist has been completed, confirming that the workload has been approved by the CSM.",
    "The SFDC Pre-Onboarding information, NBM deck, and TFW notes (RCs, sizing, etc.) have been completed.",
    "The CSM has generated an initial version of the Success Plan based on data gathered during the sales phase."
  ],
  "Support Go-Live Lookback": [
    "A Success Plan has been created.",
    "Required capabilities and technical metrics to be evaluated post-go-live have been defined.",
    "PreSales SA sizing details are available.",
    "PS reports have been provided where applicable."
  ],
  "Support Go-Live": [
    "A Success Plan has been created.",
    "Required capabilities and technical metrics to be evaluated post-go-live have been defined.",
    "PreSales SA sizing details are available.",
    "PS reports have been provided where applicable."
  ],
  "Pre-Prod Design Validation": [
    "The customer is in the pre-production phase & is classified as a Standard /or/ Premier account.",
    "The customer has extensive MongoDB knowledge.",
    "They already have, and shared, a Design Schema.",
    "They shared their application access patterns (common queries & most important ones).",
    "They shared their Indexes list."
  ],
  "Design Review": [
    "The PreSales SA who worked on this opportunity is no longer assigned & available to the account.",
    "You are onboarding a Premier workload (or an opportunity exceeding $100K ERR), /OR/ you are onboarding a smaller REPLACE use case with a relational model for a Premier customer.",
    "The customer is ready to discuss their data model.",
    "We have an Architecture/ER Diagram of this workload."
  ],
  "Customer Diagram Techstack Review": [
    "The customer has provided a high-level diagram of their current tech stack architecture.",
    "The account's total consumption is $100K or more, or it is considered a Premier account.",
    "The customer is open to receiving recommendations for optimizing their environment.",
    "If the account has a PreSales SA assigned, they are aware of this initiative."
  ]
};
// ------------------------------------------------------------------------

const RunbookSelector: React.FC<RunbookSelectorProps> = ({ setPostDescription, selectedEngagement, runbook, setRunbook, registerValidation, unregisterValidation }) => {
  useEffect(() => {
    // Register and unregister `RunbookSelector`-specific validation, if needed.
    registerValidation("RunbookSelector", validateRunbookSelector);
    console.log("MOUNTING RunbookSelector");
    return () => { // Unmounting: clean & Reset runbook to an empty string on unmount
      unregisterValidation("RunbookSelector");
      console.log("Unmounting RunbookSelector");
      setRunbook(null); 
    };}, []);

  const validateRunbookSelector = (): ValidationError => {
    if (runbook === null) return { validation: false, message: "Please select a runbook." };
    return {validation: true, message: ""};
  };

  return (
    <>
      <Label className="fieldMargin" id="description-label" htmlFor="descriptionTextArea">
        Runbook *
      </Label>
      <Description>
        Find detailed explanations of our Runbooks on{' '}
        <a
          href="https://wiki.corp.mongodb.com/display/10GEN/CS+x+RSC%3A+Runbook+Cheatsheet"
          target="_blank"
          rel="noopener noreferrer"
        >
          this page
        </a>.
      </Description>
      <Select
        className="fieldMargin"
        label=""
        name="runbook"
        baseFontSize={13}
        onChange={(selectedValue) => setRunbook(selectedValue)}
      >
        <OptionGroup label="SELECTION">
          <Option value="Design Review">Pre-Prod Design Review</Option>
        </OptionGroup>
        <OptionGroup label="ONBOARDING">
          <Option value="Technical Success Plan">Technical Success Plan</Option>
          <Option value="Pre-Prod Design Validation">Pre-Prod Design Validation</Option>
          <Option value="Support Go-Live">Go-Live Support</Option>
          <Option value="Support Go-Live Lookback">Post Go-live Lookback</Option>
        </OptionGroup>
        <OptionGroup label="CONSUMPTION">
          <Option value="Metric Review">Atlas Metrics Review</Option>
          <Option value="Backup Optimization">Backup Optimization</Option>
          <Option value="Cluster Optimization">Cluster Optimization</Option>
          <Option value="Customer Diagram Techstack Review">Customer Diagram Techstack Review</Option>
        </OptionGroup>
      </Select>
      {runbook && (
        <>
          <Banner variant="info" className="bannerStyle fieldMargin">
            <strong>{runbook}</strong>
            <br />
            <br />
            {runbookDescriptions[runbook]}
            <br />
            <br />
            To deliver this engagement, ensure the following prerequisites are met:
            {runbookPreReq[runbook] && Array.isArray(runbookPreReq[runbook]) ? (
              <ul>
                {runbookPreReq[runbook].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            ) : (
              <p>{runbookPreReq[runbook]}</p>
            )}
      </Banner>
       
    </>
  )}
  </>
  );
}
export default RunbookSelector;